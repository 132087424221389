import { FC, PropsWithChildren } from 'react';
// This file is empty on purpose, as we don't want any mock-related code to end up in production. All containers that
//  rely on mocks will actually have a 'fileReplacements' entry in their project.json for the development configuration
//  that will swap this file with index.dev.ts on run time. This way, mocks are only available when ran locally.

export default async () => {};

export const MockConfigProvider: FC<PropsWithChildren> = ({ children }) => {
  return children;
};
