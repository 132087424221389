import { FC } from 'react';

import { StackContainer } from '@custom-components/selfservice';
import { SelfservicePageTitle } from '@custom-components/SelfservicePageTitle';
import { Placeholder, useLayoutData } from '@sitecore/common';
import { Box, PageGrid, Stack, SkipToMain } from '@sparky';

export const NoGridLayout: FC = () => {
  const { route } = useLayoutData();

  const pageTitle =
    typeof route?.fields?.pageTitle?.value === 'string' ? route.fields.pageTitle.value : 'Page title missing';
  const hasMain = !!route.placeholders['jss-main']?.length;

  return (
    <>
      <Placeholder name="jss-meta" />

      <StackContainer>
        <SkipToMain.SkipLink />
        <header>
          <Placeholder name="jss-header" />
          <Placeholder name="jss-navigationbar" />
          <Placeholder name="jss-notifications" />
        </header>
        <Stack.Item grow>
          <SkipToMain.Main>
            {pageTitle.length > 0 && (
              <Stack.Item>
                <PageGrid>
                  <PageGrid.Item
                    gridColumn={{ initial: '1/-1', md: `${hasMain ? 4 : 1}/-1`, lg: `${hasMain ? 4 : 1}/10` }}>
                    <SelfservicePageTitle title={pageTitle} />
                  </PageGrid.Item>
                </PageGrid>
              </Stack.Item>
            )}

            <Stack.Item>
              <Box paddingBottom={{ initial: '8', md: '12' }}>
                <Placeholder name="jss-no-grid" />
              </Box>
              {hasMain && (
                <PageGrid>
                  <PageGrid.Item gridColumn={{ initial: '1/-1', lg: '1/-1' }} gridRowStart="2">
                    <Box>
                      <Stack gap="12">
                        <Placeholder name="jss-main" />
                      </Stack>
                    </Box>
                  </PageGrid.Item>
                </PageGrid>
              )}
            </Stack.Item>
          </SkipToMain.Main>
        </Stack.Item>
        <footer>
          <Placeholder name="jss-footer" />
        </footer>
      </StackContainer>
    </>
  );
};
