// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgLabelIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path d="M21.41 13 11.29 2.88C10.73 2.32 9.96 2 9.17 2H4c-1.1 0-2 .9-2 2v5.17c0 .8.32 1.56.88 2.12L13 21.41a1.983 1.983 0 0 0 2.82 0l5.59-5.59c.78-.78.78-2.05 0-2.83zM7 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2" />
  </Icon>
);
export default SvgLabelIcon;
SvgLabelIcon.displayName = 'SvgLabelIcon';
