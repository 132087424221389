// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgSaveIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path d="M11.5 4c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5-.67 1.5-1.5 1.5-1.5-.67-1.5-1.5m6.91 13.72-.3 2.4c-.06.5-.49.88-.99.88h-.62c-.55 0-1-.45-1-1v-.88a10.2 10.2 0 0 1-5 .13V20c0 .55-.45 1-1 1h-.62c-.5 0-.93-.38-.99-.88l-.25-2.03c-1.43-.89-2.47-2.15-2.91-3.6H4c-.55 0-1-.45-1-1v-1c0-.55.45-1 1-1h.73c.42-1.4 1.41-2.63 2.77-3.51v-2.1c0-.23.2-.41.43-.38.58.09 1.59.4 2.15 1.35.84-.23 1.73-.35 2.67-.35C17.31 6.5 21 9.41 21 13c0 1.86-1 3.54-2.59 4.72M10.5 12c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5c0 .28.22.5.5.5s.5-.22.5-.5.22-.5.5-.5.5.22.5.5.22.5.5.5.5-.22.5-.5" />
  </Icon>
);
export default SvgSaveIcon;
SvgSaveIcon.displayName = 'SvgSaveIcon';
